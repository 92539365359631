<template>
    <div class="section index-section" key="flashed">
        <div class="section-title">
            <h3>Wurden Sie in den letzten 12 Monaten schon mal geblitzt?</h3>
            <p>Bitte geben Sie an, ob Sie in den letzten zwölf Monaten bereits geblitzt wurden.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.flashed" name="radio-3" value="Ja" id="question-14-1">
            <label for="question-14-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-checked.svg"></span><span>Ja</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.flashed" name="radio-3" value="Nein" id="question-14-2">
            <label for="question-14-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-cancel.svg"></span><span>Nein</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.flashed" name="radio-3" value="unsicher" id="question-14-3">
            <label for="question-14-3" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ask-question.svg"></span><span>Weiß nicht genau</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Flashed',
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.flashed = '';
  }
}
</script>